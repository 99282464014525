export const BASE_URL = 'https://na2-roadmoto.com/';
export const TENANT_HEADER = 'RD';
export const DEFAULT_MAP_ZOOM = 8;

export const HOUR_SELECTION_12H = [
  { value: "0000", label: "12 AM",disabled:false },
  { value: "0030", label: "12:30 AM" ,disabled:false },
  { value: "0100", label: "1 AM" ,disabled:false },
  { value: "0130", label: "1:30 AM" ,disabled:false },
  { value: "0200", label: "2 AM" ,disabled:false },
  { value: "0230", label: "2:30 AM" ,disabled:false },
  { value: "0300", label: "3 AM" ,disabled:false },
  { value: "0330", label: "3:30 AM" ,disabled:false },
  { value: "0400", label: "4 AM" ,disabled:false },
  { value: "0430", label: "4:30 AM" ,disabled:false },
  { value: "0500", label: "5 AM" ,disabled:false },
  { value: "0530", label: "5:30 AM" ,disabled:false },
  { value: "0600", label: "6 AM" ,disabled:false },
  { value: "0630", label: "6:30 AM" ,disabled:false },
  { value: "0700", label: "7 AM" ,disabled:false },
  { value: "0730", label: "7:30 AM" ,disabled:false },
  { value: "0800", label: "8 AM" ,disabled:false },
  { value: "0830", label: "8:30 AM" ,disabled:false },
  { value: "0900", label: "9 AM" ,disabled:false },
  { value: "0930", label: "9:30 AM" ,disabled:false },
  { value: "1000", label: "10 AM" ,disabled:false },
  { value: "1030", label: "10:30 AM" ,disabled:false },
  { value: "1100", label: "11 AM" ,disabled:false },
  { value: "1130", label: "11:30 AM" ,disabled:false },
  { value: "1200", label: "12 PM" ,disabled:false },
  { value: "1230", label: "12:30 PM" ,disabled:false },
  { value: "1300", label: "1 PM" ,disabled:false },
  { value: "1330", label: "1:30 PM" ,disabled:false },
  { value: "1400", label: "2 PM" ,disabled:false },
  { value: "1430", label: "2:30 PM" ,disabled:false },
  { value: "1500", label: "3 PM" ,disabled:false },
  { value: "1530", label: "3:30 PM" ,disabled:false },
  { value: "1600", label: "4 PM" ,disabled:false },
  { value: "1630", label: "4:30 PM" ,disabled:false },
  { value: "1700", label: "5 PM" ,disabled:false },
  { value: "1730", label: "5:30 PM" ,disabled:false },
  { value: "1800", label: "6 PM" ,disabled:false },
  { value: "1830", label: "6:30 PM" ,disabled:false },
  { value: "1900", label: "7 PM" ,disabled:false },
  { value: "1930", label: "7:30 PM" ,disabled:false },
  { value: "2000", label: "8 PM" ,disabled:false },
  { value: "2030", label: "8:30 PM" ,disabled:false },
  { value: "2100", label: "9 PM" ,disabled:false },
  { value: "2130", label: "9:30 PM" ,disabled:false },
  { value: "2200", label: "10 PM" ,disabled:false },
  { value: "2230", label: "10:30 PM" ,disabled:false },
  { value: "2300", label: "11 PM" ,disabled:false },
  { value: "2330", label: "11:30 PM" ,disabled:false },
];
export const TIME_OPTIONS = [
  { value: '06:00', label: '6:00 am' },
  { value: '06:30', label: '6:30 am' },
  { value: '07:00', label: '7:00 am' },
  { value: '07:30', label: '7:30 am' },
  { value: '08:00', label: '8:00 am' },
  { value: '08:30', label: '8:30 am' },
  { value: '09:00', label: '9:00 am' },
  { value: '09:30', label: '9:30 am' },
  { value: '10:00', label: '10:00 am' },
  { value: '10:30', label: '10:30 am' },
  { value: '11:00', label: '11:00 am' },
  { value: '11:30', label: '11:30 am' },
  { value: '12:00', label: '12:00 pm' },
  { value: '12:30', label: '12:30 pm' },
  { value: '13:00', label: '1:00 pm' },
  { value: '13:30', label: '1:30 pm' },
  { value: '14:00', label: '2:00 pm' },
  { value: '14:30', label: '2:30 pm' },
  { value: '15:00', label: '3:00 pm' },
  { value: '15:30', label: '3:30 pm' },
  { value: '16:00', label: '4:00 pm' },
  { value: '16:30', label: '4:30 pm' },
  { value: '17:00', label: '5:00 pm' },
  { value: '17:30', label: '5:30 pm' },
  { value: '18:00', label: '6:00 pm' },
  { value: '18:30', label: '6:30 pm' },
  { value: '19:00', label: '7:00 pm' },
  { value: '19:30', label: '7:30 pm' },
  { value: '20:00', label: '8:00 pm' },
  { value: '20:30', label: '8:30 pm' },
  { value: '21:00', label: '9:00 pm' },
  { value: '21:30', label: '9:30 pm' },
  { value: '22:00', label: '10:00 pm' },
];

export const LOCATION_OPTIONS = [
  { value: 1, label: 'Duarte, CA' },
  { value: 4, label: 'Simi Valley, CA' },
];

export const EQUIP_TYPE_OPTIONS = [
  { value: '16-Enclosed', label: "16' Enclosed Trailer" },
  { value: '18-Enclosed', label: "18' Enclosed Trailer" },
  { value: '20-Enclosed', label: "20' Enclosed Trailer" },
  { value: '24-Enclosed', label: "24' Enclosed Trailer" },
  { value: '28-Enclosed', label: "28' Enclosed Trailer" },
  { value: '18-Flatbed', label: "18' Flatbed Trailer" },
  { value: '20-Flatbed', label: "20' Flatbed Trailer" },
  { value: '24-Flatbed', label: "24' Flatbed Trailer" },
  { value: '28-Flatbed', label: "28' Flatbed Trailer" },
  { value: '32-Flatbed', label: "32' Flatbed Trailer" },
];

export const STATE_OPTIONS = [
  { value: 'AK', label: 'Alaska' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
];

export const PROD_PAGE_TITLE_MAX_LEN = 44;
export const PROD_CARD_TITLE_MAX_LEN = 34;
